import { createBrowserRouter, RouteObject, Navigate } from "react-router-dom";
import { FullContainerSpinner } from "../components/common/fullContainerSpinner";
import { MainLayout } from "../layouts/mainLayout";
import { ProtectedRoute } from "../hightOrderComponents/protectedRoute";
import { UnProtectedRoute } from "../hightOrderComponents/unProtectedRoute";
import { Suspense } from "react";
import { Page404 } from "../pages/404page";
import {
  StorePageLazy,
  ExtensionPageLazy,
  SkuPageLazy,
  SkusPageLazy,
  StockPageLazy,
  PricesPageLazy,
  CompaniesPageLazy,
  LoginPageLazy,
  SuggestionsPageLazy,
  ActiveSessionsPageLazy,
  ReviewsPageLazy,
  ReviewsStatisticsPageLazy,
} from "./imports";

export const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/stores" />,
      },
      {
        path: "stores",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <StorePageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "prices",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <PricesPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "reviews",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <ReviewsPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "reviews-statistics",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <ReviewsStatisticsPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "chrome-ext",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <ExtensionPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "stock",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <StockPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "suggestions",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <SuggestionsPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "companies",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Suspense fallback={<FullContainerSpinner />}>
                  <CompaniesPageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "skus",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Suspense fallback={<FullContainerSpinner />}>
                  <SkusPageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: "view",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<FullContainerSpinner />}>
                      <SkuPageLazy />
                    </Suspense>
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "active-sessions",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FullContainerSpinner />}>
              <ActiveSessionsPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <UnProtectedRoute>
        <Suspense fallback={null}>
          <LoginPageLazy />
        </Suspense>
      </UnProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export const router = createBrowserRouter(routesConfig);
