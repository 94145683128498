import { useReactiveVar } from "@apollo/client";
import { Suspense, useEffect } from "react";
import { FullContainerSpinner } from "../common/fullContainerSpinner";
import { CommonPortalToolbar } from "../common/portalToolbar";
import { toolbarContentVar } from "../../store/toolbar.store";
import { toolbarContentComponents } from "./toolbarContent";

export const MainLayoutToolbar = () => {
  const toolbarContent = useReactiveVar(toolbarContentVar);

  const Component = toolbarContent?.type
    ? toolbarContentComponents[toolbarContent.type]
    : null;

  const handleClose = () => toolbarContentVar(undefined);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <CommonPortalToolbar
      show={!!toolbarContent?.type}
      handleClose={handleClose}
      className={`common-portal-toolbar-type__${toolbarContent?.type}`}
    >
      {toolbarContent?.type && (
        <Suspense fallback={<FullContainerSpinner />}>
          <Component
            data={toolbarContent?.data as any}
            handleClose={handleClose}
          />
        </Suspense>
      )}
    </CommonPortalToolbar>
  );
};
