import { ReactLazyPreload } from "../helpers/routes";

const StorePageLazy = ReactLazyPreload(() =>
  import("../pages/store/storePage").then((m) => ({
    default: m.StorePage,
  })),
);
const ReviewsPageLazy = ReactLazyPreload(() =>
  import("../pages/reviews/reviewsPage").then((m) => ({
    default: m.ReviewsPage,
  })),
);
const ReviewsStatisticsPageLazy = ReactLazyPreload(() =>
  import("../pages/reviews/reviewsStatisticsPage").then((m) => ({
    default: m.ReviewsStatisticsPage,
  })),
);
const ExtensionPageLazy = ReactLazyPreload(() =>
  import("../pages/extensionPage").then((m) => ({
    default: m.ExtensionPage,
  })),
);
const SkusPageLazy = ReactLazyPreload(() =>
  import("../pages/sku/skus").then((m) => ({
    default: m.SkusPage,
  })),
);
const SkuPageLazy = ReactLazyPreload(() =>
  import("../pages/sku/sku").then((m) => ({
    default: m.SkuPage,
  })),
);
const StockPageLazy = ReactLazyPreload(() =>
  import("../pages/stock/stockPage").then((m) => ({
    default: m.StockPage,
  })),
);
const PricesPageLazy = ReactLazyPreload(() =>
  import("../pages/prices/pricesPage").then((m) => ({
    default: m.PricesPage,
  })),
);
const CompaniesPageLazy = ReactLazyPreload(() =>
  import("../pages/companies/companiesPage").then((m) => ({
    default: m.CompaniesPage,
  })),
);
const LoginPageLazy = ReactLazyPreload(() =>
  import("../pages/loginPage").then((m) => ({
    default: m.LoginPage,
  })),
);
const SuggestionsPageLazy = ReactLazyPreload(() =>
  import("../pages/suggestions/suggestionsPage").then((m) => ({
    default: m.SuggestionsPage,
  })),
);
const ActiveSessionsPageLazy = ReactLazyPreload(() =>
  import("../pages/dev/activeSessionsPage").then((m) => ({
    default: m.ActiveSessionsPage,
  })),
);

export {
  StorePageLazy,
  ExtensionPageLazy,
  SkusPageLazy,
  SkuPageLazy,
  StockPageLazy,
  PricesPageLazy,
  CompaniesPageLazy,
  LoginPageLazy,
  SuggestionsPageLazy,
  ActiveSessionsPageLazy,
  ReviewsPageLazy,
  ReviewsStatisticsPageLazy,
};
