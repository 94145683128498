import { ComponentType } from "react";
import { ICompany } from "../../@types/graphql";
import { IReview } from "../../@types/rest/reviews";
import { ReactLazyPreload } from "../../helpers/routes";

export type CompanyContentProps = { data: ICompany; handleClose: () => void };
export type SkuContentProps = { data: { id: string }; handleClose: () => void };
export type PricesContentProps = {
  data: undefined;
  handleClose: () => void;
};
export type ReviewContentProps = {
  data: IReview;
  handleClose: () => void;
};
export type ToolbarContentComponents = {
  company: ComponentType<CompanyContentProps>;
  sku: ComponentType<SkuContentProps>;
  prices: ComponentType<PricesContentProps>;
  review: ComponentType<ReviewContentProps>;
};

export const toolbarContentComponents: ToolbarContentComponents = {
  company: ReactLazyPreload(() =>
    import("./contents/companyToolbarContent").then((m) => ({
      default: m.CompanyToolbarContent,
    })),
  ),
  sku: ReactLazyPreload(() =>
    import("./contents/skuToolbarContent").then((m) => ({
      default: m.SkuToolbarContent,
    })),
  ),
  prices: ReactLazyPreload(() =>
    import("./contents/pricesToolbarContent").then((m) => ({
      default: m.PricesToolbarContent,
    })),
  ),
  review: ReactLazyPreload(() =>
    import("./contents/reviewToolbarContent").then((m) => ({
      default: m.ReviewToolbarContent,
    })),
  ),
};
