import { QueryClientProvider } from "@tanstack/react-query";
import { FC, PropsWithChildren, Suspense, lazy } from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "./api/graphql/client";
import { queryClient } from "./api/rest/client";

const ReactQueryDevtoolsProduction = lazy(() =>
  import("@tanstack/react-query-devtools").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        {IS_DEV && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction initialIsOpen={false} />
          </Suspense>
        )}
        {children}
      </QueryClientProvider>
    </ApolloProvider>
  );
};
