import { useMemo, useState, MouseEvent, SetStateAction, Dispatch } from "react";

type EventHandlers = {
  onMouseOver: (event: MouseEvent<HTMLElement>) => void;
  onMouseOut: (event: MouseEvent<HTMLElement>) => void;
};

export const useHover = (): {
  hovered: boolean;
  eventHandlers: EventHandlers;
  setHovered: Dispatch<SetStateAction<boolean>>;
} => {
  const [hovered, setHovered] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseOver: () => {
        setHovered(true);
      },
      onMouseOut: () => {
        setHovered(false);
      },
    }),
    [],
  );

  return { hovered, eventHandlers, setHovered };
};
