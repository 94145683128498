import { FC, useMemo } from "react";
import { Link, matchPath, useLocation, useMatch } from "react-router-dom";
import { useHover } from "../../hooks/useHover";

import Chrome from "../../assets/icons/chrome.svg?react";
import Store from "../../assets/icons/store.svg?react";
import Promotion from "../../assets/icons/promotion.svg?react";
import Ruble from "../../assets/icons/ruble.svg?react";
import Sales from "../../assets/icons/sales.svg?react";
import Garage from "../../assets/icons/garage.svg?react";
import Price from "../../assets/icons/price.svg?react";
import Logo from "../../assets/icons/logo.svg?react";
import Reviews from "../../assets/icons/reviews.svg?react";
import Statistics from "../../assets/icons/statistics.svg?react";

import "../../styles/components/layouts/sidebar.css";

const links = [
  {
    name: "Магазины",
    link: "/stores",
    icon: Store,
  },
  {
    name: "Продвижение",
    icon: Promotion,
    children: [
      {
        name: "SKU",
        icon: Ruble,
        link: "/skus",
      },
    ],
  },
  {
    name: "Акции",
    icon: Sales,
    children: [
      {
        name: "Сток",
        icon: Garage,
        link: "/stock",
      },
      {
        name: "Цены на товары",
        icon: Ruble,
        link: "/prices",
      },
      {
        name: "Установка цены в акциях",
        icon: Price,
        link: "/suggestions",
      },
    ],
  },
  {
    name: "Акции",
    icon: Reviews,
    children: [
      {
        name: "Отзывы покупателей",
        link: "/reviews",
        icon: Reviews,
      },
      {
        name: "Статистика по отзывам",
        link: "/reviews-statistics",
        icon: Statistics,
      },
    ],
  },
];

const LinkSidebarItem: FC<
  (typeof links)[0] & { isActive: boolean; showName?: boolean }
> = ({ name, link, isActive, icon: Icon, showName }) => {
  return (
    <Link
      className={`main-sidebar-item main-sidebar-item-active__${!!isActive}`}
      to={link}
    >
      {Icon && <Icon className="main-sidebar-item-icon" />}
      {showName && name}
    </Link>
  );
};

const LinkRootItem: FC<{
  icon: FC<{ className?: string }>;
  children: (typeof links)[0][];
}> = ({ children, icon: Icon }) => {
  const { hovered, eventHandlers, setHovered } = useHover();
  const { pathname } = useLocation();

  const childrenLinkActive = useMemo(() => {
    if (!children?.length) return false;

    return (
      children.find((i) => matchPath({ path: i.link, end: false }, pathname))
        ?.link || false
    );
  }, [children, pathname]);

  return (
    <div {...eventHandlers} className="main-sidebar-item-root-wrapper">
      <div
        className={`main-sidebar-item main-sidebar-item-active__${!!childrenLinkActive}`}
      >
        {Icon && <Icon className="main-sidebar-item-icon" />}
      </div>
      <div data-hovered={hovered} className="main-sidebar-item-children">
        {children.map(({ name: cName, icon: CIcon, link: cLink }) => {
          return (
            <Link
              data-active={childrenLinkActive === cLink}
              onClick={() => setHovered(false)}
              to={cLink}
              className="main-sidebar-item-root-child"
              key={cLink}
            >
              <CIcon />
              {cName}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const SidebarItem: FC<
  (typeof links)[0] & { children?: (typeof links)[0][] }
> = ({ name, link, icon: Icon, children }) => {
  const { pathname } = useLocation();
  const isPathActive =
    !!useMatch(link || { path: "nonpath" }) ||
    (pathname === "/companies" && link === "/stores");

  if (children?.length) return <LinkRootItem children={children} icon={Icon} />;

  return (
    <LinkSidebarItem
      link={link}
      isActive={isPathActive}
      name={name}
      icon={Icon}
    />
  );
};

export const Sidebar = () => {
  const isChromeActive = !!useMatch({ path: "/chrome-ext" });

  return (
    <div className="main-sidebar">
      <div className="main-sidebar-upper">
        <Link to="/" className="main-sidebar-logo">
          <Logo />
        </Link>
        <div className="main-sidebar-links">
          {links.map((i, idx) => (
            <SidebarItem key={idx} {...i} />
          ))}
        </div>
      </div>

      <Link
        to="/chrome-ext"
        className={`main-sidebar-plugin-btn-wrapper main-sidebar-plugin-btn-wrapper-active__${isChromeActive} `}
      >
        <div className="main-sidebar-plugin-btn">
          <Chrome />
        </div>
      </Link>
    </div>
  );
};
